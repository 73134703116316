<template>
    <v-list>
        <v-list-item-group mandatory>
            <!-- <v-list-item dense to="/member/point/charge">
                <v-list-item-title>포인트 충전</v-list-item-title>
            </v-list-item> -->
            <v-list-item dense to="/member/point/points">
                <v-list-item-title>포인트 내역</v-list-item-title>
            </v-list-item>
            <v-list-item dense to="/member/point/point-gift">
                <v-list-item-title>포인트 선물하기</v-list-item-title>
            </v-list-item>
            <v-list-item dense to="/member/point/gifts">
                <v-list-item-title>포인트 선물내역</v-list-item-title>
            </v-list-item>
        </v-list-item-group>
    </v-list>

</template>

<script>
export default {
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        scope(){
            return this.$store.state.scope;
        },
        logoff(){
            return !this.$store.state.accessToken;
        },
        siteInfo(){
            return this.$store.state.siteInfo;
        }
    },
    mounted(){
        this.$store.dispatch("getSiteInfo");
    }
}
</script>

<style scoped>
.v-list-item__title {
    font-size: 16px;
}
</style>